import React from "react"

import App from "../components/App"
import Header from "../components/Header"
import SEO from "../components/seo"
import Leistungen from "../components/Leistungen"
import Kontakt from "../components/Kontakt/Kontakt"


const IndexPage = () => (
  <App>
    <SEO title="Home"/>
      <Header hero/>
      <Leistungen/>
      <Kontakt/>
  </App>
)

export default IndexPage
