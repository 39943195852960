import React, { Component } from 'react'

import './loading.css'

class Loading extends Component {
    
    render() {
        return (<div class="spinner-three-dots">
                    <div class="dot-1"></div>
                    <div class="dot-2"></div>
                    <div class="dot-3"></div>
                </div>)
    }
  }
  
  export default Loading;