import React, {useState} from 'react'
import Sensor from 'react-visibility-sensor'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import './macbook.css'

export default () => {
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "website-mockup.jpg" }) {
        childImageSharp { 
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

    const [visible, setVisible] = useState(false)
    return(
        <Sensor partialVisibility onChange={(isVisible) => setVisible(isVisible)}>
            <div class={"macbook mt-5" + (visible ? " visible" : "")}>
                <div class="macbook-lid">
                <div class="macbook-screen">
                    <div class="macbook-content">
                        <Img fluid={data.file.childImageSharp.fluid}/>
                    </div>
                </div>
                </div>
                <div class="macbook-base"></div>
            </div>
        </Sensor>
    )
}
