import React, {useState} from "react"
import {Col} from "react-bootstrap"
import {navigate} from "gatsby"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import './hosting.css'

export default () => {

    return(
        <article className="d-flex flex-wrap flex-row justify-content-between">
            <Col xs={12} lg={5} className="order-lg-1">
            <h2 className="title text-uppercase font-weight-light text-primary">Hosting-Management</h2>
            <p className="description">
            Sie möchten eine Webseite betreiben oder einfach nur E-Mailadressen mit eigener Internetadressefür Sie privat oder Ihre Firma?<br/><br/>
            Lassen Sie uns Verwaltung der nötigen Verträge, der Konfiguration und des Betriebs übernehmen.
            Warum? - Weil Sie besseres zu tun haben als sich mit solchen Themen in Ihrem Alltag herumzuschlagen.<br/>
            Wir übernehmen die komplette Verwaltung und den Bezug der Ressourcen für Sie und stehen Ihnen mit unserem Support bei Problemen oder Fragen zur Verfügung.
            Sie nutzen Ihr gebuchtes Paket einfach - unkomplziert und fertig konfiguriert.<br/><br/>
            Buchen Sie über uns Domains, E-Mailadressen &amp; Postfächer, Webspace und/oder fertig konfigurierte Content-Management-Systeme.
            </p>
            </Col>
            <Col xs={12} lg={7} className="order-lg-0 d-flex align-items-end">
                <section className="pricing py-5 col-12">
                    <div className="container">
                        <div className="row align-items-stretch justify-content-around">
                            <div className="card mb-5 mb-lg-0 bg-dark text-light shadow col-lg-5">
                                <div className="card-body d-flex flex-column h-100 justify-content-between px-0">
                                    <div>
                                        <h5 className="card-title text-muted text-uppercase text-center text-primary">Mail</h5>
                                        <h6 className="card-price text-center">ab 2,50€ <small>/ Monat</small></h6>
                                        <hr/>
                                        <ul>
                                        <li className="py-2"><CheckCircleIcon className="mr-lg-2 max-25"/> Wunsch-Domain</li>
                                        <li className="py-2"><CheckCircleIcon className="mr-lg-2 max-25"/> E-Mail Adressen</li>
                                        <li className="py-2"><CheckCircleIcon className="mr-lg-2 max-25"/> Premium Service</li>
                                        <li className="py-2"><CheckCircleIcon className="mr-lg-2 max-25"/> Einrichtung</li>
                                        </ul>
                                    </div>
                                    <a className="btn btn-block btn-primary text-uppercase align-self-end" onClick={() => navigate("#kontakt")}>Angebot anfordern</a>
                                </div>
                            </div>
                            <div className="card mb-5 mb-lg-0 bg-dark text-light shadow col-lg-5">
                                <div className="card-body d-flex flex-column h-100 justify-content-between px-0">
                                    <div>
                                        <h5 className="card-title text-muted text-uppercase text-center text-primary">Webseite</h5>
                                        <h6 className="card-price text-center">ab 4,99€ <small>/ Monat</small></h6>
                                        <hr/>
                                        <ul>
                                            <li className="py-2"><CheckCircleIcon className="mr-lg-2 max-25"/> Wunsch-Domain</li>
                                            <li className="py-2"><CheckCircleIcon className="mr-lg-2 max-25"/> E-Mail Adressen</li>
                                            <li className="py-2"><CheckCircleIcon className="mr-lg-2 max-25"/> Premium Service</li>
                                            <li className="py-2"><CheckCircleIcon className="mr-lg-2 max-25"/> Einrichtung</li>
                                            <li className="py-2"><CheckCircleIcon className="mr-lg-2 max-25"/> Webspace</li>
                                            <li className="py-2"><CheckCircleIcon className="mr-lg-2 max-25"/> Inhalte anpassen</li>
                                        </ul>
                                    </div> 
                                    <a className="btn btn-block btn-primary text-uppercase align-self-end" onClick={() => navigate("#kontakt")}>Angebot anfordern</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Col>
        </article>
    )
}
