import React, {useState} from "react"
import {Col} from "react-bootstrap"

import Macbook from "./Macbook"

export default () => {

    return(
        <article className="d-flex flex-wrap flex-row justify-content-between">
            <Col xs={12} lg={7}>
            <h2 className="title text-uppercase font-weight-light text-primary">Webdesign &amp; -entwicklung</h2>
            <p className="description">
                Lassen Sie Ihre private Webseite oder Ihren professionellen Unternehmensauftritt im Internet durch uns erstellen.<br/><br/>
                Egal um welche Art von Webseite es sich handelt, wir sind Ihr kompetenter Partner von der Beratung,über das Design bis hin zur Entwicklung und Umsetzung.<br/>
                Sie wissen noch nicht genau was Sie benötigen oder brauchen Ideen? Wir helfen Ihnen!
                Auch wenn Sie bereits konkrete Vorstellungen von Ihrem gewünschten Ergbenis haben,sind wir der richtige Ansprechpartner für Sie!<br/><br/>
                Durch unsere langjährige Erfahrung erstellen wir Ihre Webseite optisch ansprechend und mit aktuellster Technologie,sodass Ihr Unternehmen und Ihre Marke in dem Licht glänzt, so wie sie es verdient.
                Mit uns werden Unternehmenspräsenzen bis hin zu komplexen Webanwendungen zum Erfolg!
            </p>
            </Col>
            <Col xs={12} lg={4} className="pt-5">
                <Macbook/>
            </Col>
        </article>
    )
}
