import React, {useState, useEffect, useContext} from "react"
import { useDispatch, useSelector} from 'react-redux'
import HCaptcha from '@hcaptcha/react-hcaptcha';

import {validators} from '../../functions'
import {sendContact} from '../../state/actions'
import NotificationContext from '../../components/Notification/NotificationContext'

import './style.css'
import Loading from "../Loading/Loading";

export default () => {
    const notification = useContext(NotificationContext);
    const state = useSelector(state => state.sendContactForm);
    const dispatch = useDispatch();

    const [name, setName] = useState('')
    const [mail, setMail] = useState('')
    const [text, setText] = useState('')
    const [consent, setConsent] = useState('')
    const [captcha, setCaptcha] = useState('')
    const [ready, setReady] = useState(false)

    const verifyCallback = (recaptchaToken) => {
        setCaptcha(recaptchaToken)
    }

    const expiredCallback = () => {
        setCaptcha('')
    }

    const updateContactState = (setter, value) => {
        setter(value)
    }

    const handleSubmit = () => {
        if(ready){
            dispatch(sendContact({
                name: name,
                mail: mail,
                text: text,
                consent: consent,
                "h-recaptcha-response": captcha
            }, notification))
        }
    }

    useEffect(() => {
        if(
            name && 
            mail && 
            validators.validateMail(mail) && 
            text && 
            captcha && 
            consent === "set"){
            setReady(true)
        }
        else{
            setReady(false)
        }
    })

    if(state.isPosting){
        return(
            <section className="col-12 col-sm-8 col-lg-5 card bg-primary text-white form-card contactform">
            <div className="card-body">
                <h4 className="card-title text-center text-uppercase">Kontakt</h4>
                <div id="contact-form">
                <Loading/>
                </div>
            </div>
            </section>
        )
    }


    return(
       <section className="col-12 col-sm-8 col-lg-5 card bg-primary text-white form-card contactform">
            <div className="card-body">
                <h4 className="card-title text-center text-uppercase">Kontakt</h4>
                <div id="contact-form">
                    <div className="form-element form-input" id="contact-name">
                        <input id="field-name" className="form-element-field" placeholder="Ihr Vor- und Zuname (und ggf. Firma)" type="input" name="name" required onChange={event => updateContactState(setName, event.target.value)}/>
                        <div className="form-element-bar"></div>
                        <label className="form-element-label" for="field-name">Name</label>
                        <small className="form-element-hint">* Pflichtfeld</small>
                    </div>
                    <div className="form-element form-input" id="contact-mail">
                        <input id="field-mail" className="form-element-field" type="email" placeholder=" " name="mail" required onChange={event => updateContactState(setMail, event.target.value)}/>
                        <div className="form-element-bar"></div>
                        <label className="form-element-label" for="field-mail">E-Mailadresse</label>
                        <small className="form-element-hint">* Pflichtfeld</small>
                    </div>
                    <div className="form-element form-textarea" id="contact-text">
                            <textarea id="field-nachricht" className="form-element-field" name="text" placeholder=" " onChange={event => updateContactState(setText, event.target.value)}></textarea>
                            <div className="form-element-bar"></div>
                            <label className="form-element-label" for="field-nachricht">Ihre Nachricht</label>
                            <small className="form-element-hint">* Pflichtfeld</small>
                    </div>
                    <div className="form-checkbox" id="contact-consent">
                        <label className="form-checkbox-label">
                            <input className="form-checkbox-field" type="checkbox" name="consent" value="set" required onChange={event => updateContactState(setConsent, event.target.checked ? 'set' : '')}/>
                            <i className="form-checkbox-button"></i>
                            <small>Sie stimmen der Datenverarbeitung laut unserer Datenschutzerklärung zu</small>
                        </label>
                        <small className="form-element-hint">* Pflichtfeld</small>
                    </div>
                    <div className="d-flex justify-content-center mb-3 mt-0">
                    <HCaptcha
                        sitekey="b33c6349-ec14-4d40-aa10-e7923d6ba1d1"
                        onVerify={verifyCallback}
                        onErrored={expiredCallback}
                        onExpired={expiredCallback}
                    />
                    </div>
                    <div className="d-flex justify-content-center">
                        <input type="submit" className="btn btn-neutral btn-round btn-lg" value="ABSENDEN" disabled={!ready ? 'disabled' : '' } onClick={handleSubmit}/>
                    </div> 
                </div>
            </div>    
        </section>
    )
}
