import React, {useState, useEffect, useRef} from "react"
import mapboxgl from 'mapbox-gl'
import Sensor from 'react-visibility-sensor'
import useDimensions from "react-use-dimensions"
import {Container, Col} from "react-bootstrap"
import Formular from "./Formular"
import Footer from "../Footer"
import Spacer from "../Spacer"

mapboxgl.accessToken = 'pk.eyJ1Ijoiem9ycmlsbGFtZWRpYSIsImEiOiJjanIyZW9nbngwMXB2NDVxaHFmc2NxOTVmIn0.GfcEZujYKG8Ms6Wo1Tb0kQ'

let geojson = {
    type: 'ZorrillaMedia Places',
    features: [{
        type: 'Feature',
        geometry: {
        type: 'Point',
        coordinates: [8.353609, 49.989184]
        },
        properties: {
        title: 'Zorrilla Media Headquarters',
        description: 'Bischofsheim'
        }
    },
    {
        type: 'Feature',
        geometry: {
        type: 'Point',
        coordinates: [7.956226, 48.493746]
        },
        properties: {
        title: 'Zorrilla Media Customers',
        description: 'Offenburg'
        }
    },
    {
        type: 'Feature',
        geometry: {
        type: 'Point',
        coordinates: [8.683297, 49.450237]
        },
        properties: {
        title: 'Zorrilla Media Customers',
        description: 'Heidelberg'
        }
    },
    {
        type: 'Feature',
        geometry: {
        type: 'Point',
        coordinates: [8.404350, 49.035098]
        },
        properties: {
        title: 'Zorrilla Media Customers',
        description: 'Karlsruhe'
        }
    }]
};

export default () => {

    const [visible, setVisible] = useState(false)
    const [activated, setActivated] = useState(false)
    
    const [ref, { x, y, width, height }] = useDimensions()

    const [map, setMap] = useState(null);
    const mapContainer = useRef(null);

    useEffect(() => {
        const initializeMap = ({ setMap, mapContainer }) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/dark-v9',
                center: [8.35, 49.98],
                zoom: 10,
                interactive: false,
                attributionControl: false
                }).addControl(new mapboxgl.AttributionControl({
                    compact: true
                }))

            map.on("load", () => {
                setMap(map);
                map.resize();
                geojson.features.forEach(function(marker) {
                    // create a HTML element for each feature
                    let el = document.createElement('div');
                    el.className = 'marker';
                    
                    // make a marker for each feature and add to the map
                    new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map);
                });
            });
        };

        if (!map) initializeMap({ setMap, mapContainer });
    }, [map]);

        
    
    if(map && visible && !activated){
        setActivated(true)
        map.flyTo({
            center: [8.35, 49.98],
            offset: [width/-7, 0],
            duration:0
        })
        map.flyTo({
            center: geojson.features.find(place => place.properties.description === 'Offenburg').geometry.coordinates,
            offset: [width/-4, height/3],
            zoom: 8.3,
            curve: 0.5,
            speed: 0.05,
            pitch: 60,
            bearing: 30
        })
    }

    return(
        <>
        <div id="kontakt" className="col-12"><Spacer variant="large"/></div>
        <section className="mb-0 bg-dark text-light" ref={ref}>
            <Sensor partialVisibility onChange={(isVisible) => setVisible(isVisible)}>
                <div id='map' ref={el => (mapContainer.current = el)} className="col-12 mx-0 px-0"></div>
            </Sensor>
            <Container>
                <Col xs={12} className="justify-content-end d-flex mb-md-5" style={{height: "20px"}}><Formular /></Col>
                <Col xs={12}><Footer/></Col>
            </Container>
            
        </section>
        </>
    )
}
