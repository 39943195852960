import React from "react"
import {Col} from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

export default () => {

    const data = useStaticQuery(graphql`
    query {
      notebook: file(relativePath: { eq: "ci-notebook.jpg" }) {
        childImageSharp { 
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    briefpapier: file(relativePath: { eq: "ci-briefpapier.jpg" }) {
        childImageSharp { 
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    visitenkarten: file(relativePath: { eq: "ci-visitenkarten.jpg" }) {
        childImageSharp { 
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    brille: file(relativePath: { eq: "ci-brille.jpg" }) {
        childImageSharp { 
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    misc: file(relativePath: { eq: "ci-misc.jpg" }) {
        childImageSharp { 
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    console.log(data)
    return(
        <article className="d-flex flex-wrap flex-row justify-content-between">
            <Col xs={12} lg={7}>
            <h2 className="title text-uppercase font-weight-light text-primary">Printdesign &amp; Druck</h2>
            <p className="description">
            Benötigen Sie Visitenkarten für Ihre Firma?<br/>
            Sie möchten Flyer für Events, ihre Produkte oder Dienstleistungen?<br/>
            Oder geht es doch um eine andere Art von Druck und Publikation mit einer Auflage von wenigen bis hin zu tausenden Exemplaren?<br/><br/>
            Bei uns sind Sie genau richtig!<br/>
            Wir unterstützen Sie gerne bei der Gestaltung und Produktion von Print-Produkten aller Art.<br/>
            Sprechen Sie uns an, mit unserer langjährigen Erfahrung in der Mediengestaltung helfen wir Ihnen das Richtige für Sie zu finden und Ihre Wunschprodukte in höchster Präzision und Qualität zu verwirklichen.
            </p>
            </Col>
            <Col xs={12} lg={4} className="pt-5 d-flex flex-wrap flex-row px-0">
                <div className="col-6 col-lg-4 col-xl-3 px-0 d-flex flex-wrap flex-row" >
                  <div className="col-8 col-lg-12 px-0" ><Img fluid={data.visitenkarten.childImageSharp.fluid}/></div>
                  <div className="col-lg-12 px-0 d-none d-lg-block" ><Img fluid={data.misc.childImageSharp.fluid}/></div>
                  <div className="col-12 d-lg-none order-0" ><Img fluid={data.notebook.childImageSharp.fluid}/></div>
                </div>
                <div className="col-6 col-lg-8 col-xl-6 px-0" ><Img fluid={data.briefpapier.childImageSharp.fluid}/></div>
                <div className="col-6 col-lg-12 col-xl-3 px-0 py-4 flex-wrap flex-row d-none d-lg-flex" >
                  <div className="col-6 col-xl-12 px-0 order-1 order-xl-0" ><Img fluid={data.brille.childImageSharp.fluid}/></div>
                  <div className="col-6 col-xl-12 px-0 order-0 order-xl-1" ><Img fluid={data.notebook.childImageSharp.fluid}/></div>
                </div>
            </Col>
        </article>
    )
}
