import React, {useState} from "react"
import { Container} from "react-bootstrap"

import Webdev from './Webdev'
import Hosting from "./Hosting"
import Print from "./Print"
import Spacer from "../Spacer"

export default () => {
    return(
        <Container>
            <section className="section" id="leistungen">
                <div className="d-none d-md-flex"><Spacer variant="medium"/></div>
                <Webdev/>
                <Spacer variant="medium"/>
                <Hosting/>
                <Spacer variant="medium d-none d-md-flex"/>
                <Print/>
            </section>
        </Container>
    )
}
